<template>
    <modal ref="condiciones" titulo="Condiciones de la promoción" no-aceptar>
        <div class="row mx-0 my-3 justify-center text-center f-14 px-2">
            <img class="obj-cover br-10 w-100" height="130" :src="detalle_promo.imagen_show" alt="img" />
        </div>
        <div class="row mx-0 my-3 justify-center text-center f-17 f-600">
            {{ detalle_promo.nombre }}
        </div>
        <div class="row mx-0 my-3 justify-center text-center f-14 px-2" style="word-break:break-all">
            <p> {{ detalle_promo.condiciones }}</p>
        </div>
        <div class="row mx-0 my-3 f-14 px-3">
            <b class="pr-1">
                Categorías aplicadas:
            </b>
            <span v-for="(item, idx) in detalle_promo.promo_categorias" :key="idx" class="pr-1">
                {{ item.nombre }}
            </span>
        </div>
        <div class="row mx-0 my-3 f-14 px-3">
            <b class="pr-1">
                Cantidad de productos permitidos:
            </b>{{ detalle_promo.pro_permitidos }}
        </div>
        <div class="row mx-0 my-3 f-14 px-3">
            <cardProductoVer :info="detalle_promo" />
        </div>
    </modal>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    computed:{
        ...mapGetters({
            detalle_promo: 'promociones/promociones_cedis/detalle_promo'
        }),

    },
    methods: {
        toggle(){
            this.$refs.condiciones.toggle()
        }
    }
}
</script>

<style lang="css" scoped>
</style>
